import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import Container from '../components/Container';

import loadComponents from '../components/Loadable';

const Product = loadComponents('product');
const SubpageHeader = loadComponents('subpage-header');

export default function ProductTemplate({ location, data }) {
  const prevPage = location.state && location.state.prevPage;
  const product = data.wpSimpleProduct;
  // const category = product.productCategories.nodes[0];

  const removeListener = () => {
    window.removeEventListener('beforeunload', alertUser);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      removeListener();
    };
  }, []);

  const alertUser = event => {
    event.preventDefault();
    event.returnValue =
      'please note you are leaving the application page this will clear your information from the browser and you will have to start over unless you have saved your application for later';
  };

  const productProps = {
    product,
    query: location.search,
    removeListener,
  };

  return (
    <Layout
      title={product.name}
      // seo={product.seo}
      location={product.link}
      prevPage={prevPage}
      padding="0 0 5.625rem"
      cart
    >
      <article>
        <SubpageHeader
          title={product.name}
          link={product.link}
          // parent={{ title: category.name, link: category.link }}
        />
        <Container>
          <Product {...productProps} />
        </Container>
      </article>
    </Layout>
  );
}

export const productQuery = graphql`
  query ($id: String!) {
    wpSimpleProduct(id: { eq: $id }) {
      ... on WpSimpleProduct {
        id
        databaseId
        name
        link
        # ...WooProductSeo
        regularPrice
        salePrice
        price
        onSale
        # content
        # productCategories {
        #   nodes {
        #     name
        #     link
        #   }
        # }
        options {
          currency
          termsConditions
          form {
            ... on WpForm {
              ...FormFields
            }
          }
        }
        termsConditions {
          customTcs
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 576, height: 576, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
